@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/Montserrat-Bold.eot);
  src: url(../fonts/Montserrat-Bold.eot?#iefix) format("embedded-opentype"),
  url(../fonts/Montserrat-Bold.woff2) format("woff2"),
  url(../fonts/Montserrat-Bold.woff) format("woff"),
  url(../fonts/Montserrat-Bold.ttf) format("truetype")
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/Montserrat-Regular.eot);
  src: url(../fonts/Montserrat-Regular.eot?#iefix) format("embedded-opentype"),
  url(../fonts/Montserrat-Regular.woff2) format("woff2"),
  url(../fonts/Montserrat-Regular.woff) format("woff"),
  url(../fonts/Montserrat-Regular.ttf) format("truetype")
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url(../fonts/Montserrat-Italic.eot);
  src: url(../fonts/Montserrat-Italic.eot?#iefix) format("embedded-opentype"),
  url(../fonts/Montserrat-Italic.woff2) format("woff2"),
  url(../fonts/Montserrat-Italic.woff) format("woff"),
  url(../fonts/Montserrat-Italic.ttf) format("truetype")
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/Montserrat-Light.eot);
  src: url(../fonts/Montserrat-Light.eot?#iefix) format("embedded-opentype"),
  url(../fonts/Montserrat-Light.woff2) format("woff2"),
  url(../fonts/Montserrat-Light.woff) format("woff"),
  url(../fonts/Montserrat-Light.ttf) format("truetype")
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/Montserrat-Medium.eot);
  src: url(../fonts/Montserrat-Medium.eot?#iefix) format("embedded-opentype"),
  url(../fonts/Montserrat-Medium.woff2) format("woff2"),
  url(../fonts/Montserrat-Medium.woff) format("woff"),
  url(../fonts/Montserrat-Medium.ttf) format("truetype")
}